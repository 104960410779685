<template>
  <div class="table-container">
    <div
      v-if="mode == 'editor'"
      class="editor-toolbar"
      :class="isEditing ? 'editing' : ''"
    >
      <slot name="toolbar"></slot>
    </div>
    <table
      class="table table-condensed table-bordered table-hover dataTable table-striped table-responsive"
    >
      <thead>
        <tr>
          <th class="text-info">{{ $t("location") }}</th>
          <th class="text-info">{{ $t("name") }}</th>
          <th class="text-info">{{ $t("connection") }}</th>
        </tr>
      </thead>
      <tbody v-if="equipment">
        <tr>
          <td class="text-info">{{ itemLocation }}</td>
          <td class="text-info">{{ itemName }}</td>
          <td class="text-info">
            <IconConnection :state="equipment.connector_status" show="both" />
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="9">{{ $t("equipment_not_selected") }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import IconConnection from "@/components/icons/icon-connection.vue";
export default {
  name: "EquipmentTableBasicInfo",
  components: {
    IconConnection
  },
  props: {
    equipment: Object,
    panel: {
      type: Object,
      default: () => null,
      required: false
    },
    title: {
      type: String,
      default: "history",
      required: false
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  computed: {
    itemName() {
      return ((this.equipment?.name || "").replace(/\_/g, " ") || "").toUpperCase();
    },
    itemLocation() {
      return (this.equipment?.location_name || "").toUpperCase();
    }
  }
};
</script>

<style scoped>
.table {
  margin-bottom: 0;
}
.table-container {
  overflow: auto;
}
.table-container th,
table-container td {
  vertical-align: top;
  width: 33%;
}
.table-container td {
  vertical-align: top;
  white-space: nowrap;
}
.nowrap {
  white-space: nowrap;
}
tbody {
  background-color: whitesmoke;
}

.editor-toolbar {
  position: absolute;
  right: 0px;
  width: 100%;
  border-top: 3px solid #3c8dbc;
}

.editing {
  border-top-color: #f39c12;
}

.editor-toolbar > div {
  background-color: white;
}
</style>
